import React from "react"
import { Frame, addPropertyControls, ControlType } from "framer"

export function NegativeMargins({
    children,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
}) {
    return (
        <Frame
            style={{
                marginTop: `-${marginTop}px`,
                marginRight: `-${marginRight}px`,
                marginBottom: `-${marginBottom}px`,
                marginLeft: `-${marginLeft}px`,
            }}
        >
            {children}
        </Frame>
    )
}

NegativeMargins.defaultProps = {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
}

addPropertyControls(NegativeMargins, {
    marginTop: {
        type: ControlType.Number,
        title: "Margem Superior",
        defaultValue: 0,
        min: 0,
        max: 100,
        step: 1,
        displayStepper: true,
    },
    marginRight: {
        type: ControlType.Number,
        title: "Margem Direita",
        defaultValue: 0,
        min: 0,
        max: 100,
        step: 1,
        displayStepper: true,
    },
    marginBottom: {
        type: ControlType.Number,
        title: "Margem Inferior",
        defaultValue: 0,
        min: 0,
        max: 100,
        step: 1,
        displayStepper: true,
    },
    marginLeft: {
        type: ControlType.Number,
        title: "Margem Esquerda",
        defaultValue: 0,
        min: 0,
        max: 100,
        step: 1,
        displayStepper: true,
    },
})
